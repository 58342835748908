import React, {
  useRef,
  useState
} from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Slider from "react-slick";

import Footer from "src/components/footer";
import Header from "src/components/header";
import HelmetWrapper from "src/components/helmet-wrapper";
import AboutUs from "src/components/homepage/AboutUs";
import WhatWeDo from "src/components/homepage/WhatWeDo";
import { breadCrumbSchema } from "src/components/html-schema-snippets";
import Insights from "src/components/insights";
import Layout from "src/components/layout";
import PageHeaderSlide from "src/components/homepage/PageHeaderSlide";
import SliderControls from "src/components/homepage/SliderControls";
import StayInTheLoop from "src/components/stay-in-the-loop";
import getProfile from "src/templates/utils/getProfile";
import { IArticleData } from "src/components/articles-grid/articles-grid";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  IFixed,
  ILocalFile
} from "src/types/local-files";

import { IBlogArticleData } from "./latest";
import { IProjectData } from "./projects";

interface IProps {
  data: IHomeProps;
}

interface IPageLandingLinks {
  title: string;
  uri: string;
}

export interface IHomeProps {
  nodeHome: {
    title: string;
    created: string;
    changed: string;
    field_meta_description: string;
    field_hero_heading: string;
    field_about_us_who_: {
      processed: string;
    };
    field_about_us_w_: {
      processed: string;
    };
    field_hero_video_url: string;
    field_landing_page_links: IPageLandingLinks[];
    relationships: {
      field_home_insights: IBlogArticleData[];
      field_hero_image: ILocalFile<IFixed>;
      field_home_about_us_image: ILocalFile<IFixed>;
      field_mobile_hero_image: ILocalFile<IFixed>;
      field_home_latest_projects: IProjectData[];
    };
  };
}

/**
 * Make sure to replace the beginning of the amazon s3 asset url host/origin
 * with our own Gravitywell CDN version;
 * E.g. remove:
 * https://gravitywell-website-2019-assets-eu-west-1.s3.eu-west-1.amazonaws.com
 * Replace with:
 * https://cdn.gravitywell.co.uk
 */
export const slides = [
  {
    videoSrc: "https://cdn.gravitywell.co.uk/videos/home-v2/dubbii_hero.m3u8",
    imgSrc: "https://cdn.gravitywell.co.uk/videos/home-v2/dubbii_herothumbnail_w_640.0000000.jpg",
    title: "Dubbii",
    text: "Removing the shame around household tasks for ADHDers",
    link: "/projects/dubbii/"
  },
  {
    videoSrc: "https://cdn.gravitywell.co.uk/videos/home-v2/braided_space.m3u8",
    imgSrc: "https://cdn.gravitywell.co.uk/videos/home-v2/braided_spacethumbnail_w_640.0000000.jpg",
    title: "Braided Communications",
    text: "Enabling the future of deep space communication",
    link: "/projects/braided-communications/"
  },

  {
    videoSrc: "https://cdn.gravitywell.co.uk/videos/home-v2/1275_wine.m3u8",
    imgSrc: "https://cdn.gravitywell.co.uk/videos/home-v2/1275_winethumbnail_w_640.0000000.jpg",
    title: "1275 Fine Wine Collections",
    text: "Reinventing the world of wine collecting and investment",
    link: "/projects/1275-fine-wine-collections/"
  },

  {
    videoSrc: "https://cdn.gravitywell.co.uk/videos/home-v2/unflash_browsing.m3u8",
    imgSrc: "https://cdn.gravitywell.co.uk/videos/home-v2/unflash_browsingthumbnail_w_640.0000000.jpg",
    title: "unFlash",
    text: "Using AI to make the internet a safer place for people with epilepsy",
    link: "/projects/unflash/"
  },

  {
    videoSrc: "https://cdn.gravitywell.co.uk/videos/home-v2/maya_car.m3u8",
    imgSrc: "https://cdn.gravitywell.co.uk/videos/home-v2/maya_carthumbnail_w_640.0000000.jpg",
    title: "Maya",
    text: "Revolutionising the world of online tyre retail",
    link: "projects/maya/"
  }
];

const SLIDE_DURATION = 10_000; // 1000ms = 1s
const AUTOPLAY = true; // false: for easier debugging

const mapSlides = (currentCarouselSlide: number, sliderRef: React.MutableRefObject<Slider | undefined>) => slides.map((slide, i) => {
  return (
    <PageHeaderSlide
      {...slide}
      isCurrent={i === currentCarouselSlide}
      key={i}
      playerProps={{
        playing: i === currentCarouselSlide && AUTOPLAY,
        loop: i === currentCarouselSlide
      }}
      controls={(
        <SliderControls
          getSlider={() => sliderRef.current}
          current={currentCarouselSlide}
        />
      )}
    />
  );
});

const HomePage: React.FC<IProps> = ({ data }): JSX.Element => {
  const contactRef = useRef<HTMLDivElement | null>(null);
  const slider = useRef<Slider | undefined>();
  const canonicalPath = "/startups";
  const [ currentCarouselSlide, setCurrentSlide ] = useState(0);

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Home",
      path: canonicalPath
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData ];

  return (
    <Layout className="layout">
      <HelmetWrapper
        title={data.nodeHome.title}
        description={data.nodeHome.field_meta_description}
        updatedAt={data.nodeHome.changed}
        canonicalPath="/"
      >
        <script type="application/ld+json">
          {JSON.stringify(schemaJson)}
        </script>
      </HelmetWrapper>

      <Styled.Header
        role="banner"
        data-autoplay={AUTOPLAY}
      >
        <Header
          initialLogo
          initialTheme="dark"
        />

        <Slider
          fade
          className="slider"
          cssEase="0"
          pauseOnHover={false}
          slidesToShow={1}
          slidesToScroll={1}
          infinite
          arrows={false}
          autoplay={AUTOPLAY}
          autoplaySpeed={SLIDE_DURATION}
          ref={r => (slider.current = (r || undefined))}
          beforeChange={(_, index: number) => setCurrentSlide(index)}
        >
          {mapSlides(currentCarouselSlide, slider)}
        </Slider>
      </Styled.Header>

      <SliderControls
        getSlider={() => slider.current}
        current={currentCarouselSlide}
      />

      <main>
        <WhatWeDo data={data} />

        <AboutUs
          data={data}
          sideText="About us"
        />

        <Insights
          headingTag="h3"
          title="Insights"
          sideText="Latest posts"
          gradient="orangePink"
          articles={data.nodeHome.relationships.field_home_insights.slice(0, 2).map(
            post => {
              const profile = getProfile(post.relationships.author);

              const articleObj: IArticleData = {
                created: post.created,
                path: post.path.alias,
                title: post.title,
                author: profile.field_full_name,
                cover: post.relationships.field_cover_image
              };

              return articleObj;
            }
          ).concat(
            // yeah its gross but I'm told not to worry we'll be changing GW
            // site stuff when possible
            {
              created: "2019-09-19T10:18:52+00:00",
              path: "/insights/how-to-build-an-mvp/",
              title: "How to build an MVP",
              author: "Henry Osadzinski",
              cover: {
                localFile: {
                  childImageSharp: null,
                  publicURL: "https://www.gravitywell.co.uk/static/c10c371e13200ace942870d87885bb6f/6fc49/meta_2.jpg"
                },
                filename: ""
              }
            } as IArticleData
          )}
        />
      </main>

      <footer
        role="contentinfo"
        ref={contactRef}
      >
        <StayInTheLoop />

        <Footer />
      </footer>
    </Layout>
  );
};

const Styled = {
  Header: styled.header`
    --slide-duration: ${SLIDE_DURATION};


    &[data-autoplay='true'] {
      .slider .slick-slide::after {
        background: black;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
      }

      .slider .slick-slide::after {
        opacity: 1;
      }

      .slider .slick-slide.slick-active {
        &::after {
          animation: ${`fade-in ${SLIDE_DURATION}ms ease-in-out both`};
        }

        .top-content {
          .heading_small  {
            animation: fade-out ease-in both;
            animation-delay: 1000ms;
            animation-duration: ${SLIDE_DURATION - (1.5 * 1000) + "ms"};
            letter-spacing: 0.2rem;
          }
          .heading_large  {
            animation: fade-out ease-in both;
            animation-delay: 1400ms;
            animation-duration: ${SLIDE_DURATION - (1.5 * 1400) + "ms"};
          }
          .section-cta-container [href] {
            animation: fade-out ease-in both;
            animation-delay: 1700ms;
            animation-duration: ${SLIDE_DURATION - (1.5 * 1700) + "ms"};
          }
        }
      }
    }
    
    @keyframes fade-in {
      0%, 100% {
        opacity: 1;
      }
      15%, 85% {
        opacity: 0;
      }
    }

    @keyframes fade-out {
      0%, 100% {
        opacity: 0;
      }
      15%, 85% {
        opacity: 1;
      }
    }

    @keyframes content-enter {
      0% {
        transform: translateY(10px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  `
};

export const query = graphql`
  query homePageV2Query {
    nodeHome {
      created
      changed
      title
      field_meta_description
      field_hero_heading
      field_about_us_who_ {
        processed
      }
      field_about_us_w_ {
        processed
      }
      field_hero_video_url
      field_landing_page_links {
        title
        uri
      }
      relationships {
        field_home_insights {
          __typename
          ... on node__labs_article {
            title
            created
            path {
              alias
            }
            relationships {
              author: uid {
                relationships {
                  profile__team_profile {
                    ...getProfileData
                  }
                }
              }
              field_cover_image {
                ...getLocalFileData
              }
            }
          }
        }
        field_hero_image {
          localFile {
            publicURL
            childImageSharp {
              fixed(quality: 80, width: 1920, height: 700) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
        field_mobile_hero_image {
          localFile {
            publicURL
            childImageSharp {
              fixed(quality: 80, width: 768, height: 512) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
        field_home_about_us_image {
          localFile {
            publicURL
            childImageSharp {
              fixed(quality: 80, width: 1920, height: 721) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
        field_home_latest_projects {
          ...getProjectData
        }
      }
    }
  }
`;

export default HomePage;