import * as React from "react";

import { IHomeProps } from "src/pages";

import { ColumnWidthWrapper } from "../confines-wrappers";
import styled, { css } from "../../theme/styled";
import {
  fontSizeHelper,
  fontWeightHelper,
  colourHelper
} from "../../theme/theme-helper";
import Link from "../link";
import Wysiwyg from "../wysiwyg";

interface IProps {
  data: IHomeProps;
  sideText?: string;
}

/**
 * AboutUs?
 */
const AboutUs: React.FC<IProps> = ({ data, sideText }) => (
  <AboutUsStyled className="about-us">
    <ColumnWidthWrapper
      className="content-container"
      sideText={sideText}
    >
      <div className="about-us-wrapper">
        <div className="about-us-inner">
          <div className="left">
            <h3>
              Building businesses for
              {" "}

              <span style={{ whiteSpace: "nowrap" }}>
                over 20 years
              </span>
            </h3>
          </div>

          <div className="right">
            <p>
              Gravitywell is Bristol’s Venture Studio; we pull together the skills, experience, resources and funding needed to produce businesses with solid technology foundations.
              <br />

              <br />
              Helping Startups and Scaleups blossom and achieve their goals is our priority — from a Discovery Phase and best-in-class design & build through to industry-leading consultation and support with investment.

              <br />

              <br />
              Nothing is impossible and we never outsource; we deliver mobile and web apps, MVP & prototypes, investment support, consultancy, brand strategy, digital transformation and more.
            </p>
          </div>

        </div>

        <p className="workWithUs">
          <span className="off-screen">
            Click here to
            {" "}
          </span>

          <Link
            type="styled"
            color="black"
            href="/contact"
          >
            Work with us
          </Link>
        </p>
      </div>
    </ColumnWidthWrapper>
  </AboutUsStyled>
);

export default AboutUs;

const AboutUsStyled = styled.div`
  ${({ theme }) => css`
    background-color: ${colourHelper({
    theme,
    variant: "warmGrey1"
  })};

    .about-us-wrapper {
      .about-us-inner {
        margin-top: 4%;

        div {
          padding-top: 2.8rem;

          &:first-child {
            padding-top: 0;
          }

          h3 {
            font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_3"
  })};
          }
        }

        p {
              font-size: ${fontSizeHelper({
    theme,
    variant: "body_2"
  })};
            }

        .right {
          p {
            padding-top: 1em;
						line-height: 1.5;
            font-size: ${fontSizeHelper({
    theme,
    variant: "body_1"
  })};
          }
          p + p {
            margin-top: 1em;
          }
        }
      }

      .workWithUs {
        /* padding-top: 2.6rem; */
        padding-top: 6.8rem;
        text-align: center;
        font-size: ${fontSizeHelper({
    theme,
    variant: "body_1"
  })};
        font-weight: ${fontWeightHelper({
    theme,
    variant: "bold"
  })};
      }
    }

    @media (min-width: 375px) {
      .about-us-wrapper {
        .about-us-inner {
          div {
            h3 {
              font-size: ${fontSizeHelper({
    theme,
    variant: "title"
  })};
            }
          }
        }
      }
    }

		@media (min-width: 480px) {
		.about-us-wrapper  .workWithUs {
      padding-top: 8.8rem;
    }
  }

    @media (min-width: 600px) {
      .about-us-wrapper {
        .about-us-inner {
          margin-top: 1.2rem;

          div {
            h3 {
              font-size: ${fontSizeHelper({
    theme,
    variant: "display_1"
  })};
            }
          }
        }
      }
    }

		@media (min-width: 700px) {
      .about-us-wrapper {
        .about-us-inner {
          display: flex;
          position: relative;
          padding-top: 1rem;
          padding-bottom: 1rem;

          div {
            padding-top: 0;
            width: 50%;

            &:nth-child(odd) {
              padding-right: 5%;
            }
						
            &:nth-child(even) {
							padding-left: 5%;
							p {
								line-height: 1.6;
								padding-top: 0;
							}
            }

            h3 {
              font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_3"
  })};
            }
          }

        }
      }
    }

    @media (min-width: 768px) {
      .about-us-wrapper {
        .about-us-inner {
          margin-top: 1.8rem;
        }
      }
    }

    @media (min-width: 960px) {
      .about-us-wrapper {
        .about-us-inner {
          justify-content: space-between;

          div {
            padding-top: 0;
            padding-left: 0 !important;
            padding-right: 0 !important;
            width: 50%;
            max-width: 40rem;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      .about-us-wrapper {
        .about-us-inner {
          div {
            max-width: 45rem;

            h3 {
              font-size: ${fontSizeHelper({
    theme,
    variant: "display_1"
  })};
            }

						&:nth-child(odd) {
							p {
              font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_1"
  })};
            }
            }

            &:nth-child(even) {
            }
          }
        }

        .workWithUs {
          font-size: ${fontSizeHelper({
    theme,
    variant: "body_2"
  })};
        }
      }
    }

    @media (min-width: 1280px) {
      .about-us-wrapper {
        .about-us-inner {
          div {
            max-width: 55rem;


						&:nth-child(odd) {
							p {
              font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
            }
            }
          }
        }

      }
    }

    @media (min-width: 1400px) {
      .about-us-wrapper {
        .about-us-inner {
          div {
            max-width: 60rem;

            :nth-child(odd) {
							p {
              font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
            }
						}
          }
        }

      }
    }


		@media (min-width: 1440px) {
    .about-us-wrapper .workWithUs {
      padding-top: 10.2rem;
    }
  }
  `}
`;
