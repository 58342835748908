import React from "react";
import { ReactPlayerProps } from "react-player";

import { ColumnWidthWrapper } from "src/components/confines-wrappers";
import HeroVideo from "src/components/hero-video";
import PageTop from "src/components/page-top";
import styled from "src/theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "src/theme/theme-helper";

import Link from "../link/link";

export type PageHeaderSlideProps = {
  videoSrc: string;
  imgSrc: string;
  title?: string;
  text?: string;
  link?: string;
  playerProps?: ReactPlayerProps;
  isCurrent?: boolean;
};

export type WithSliderControls = PageHeaderSlideProps & {
  controls?: JSX.Element;
};

const PageHeaderSlide: React.FC<WithSliderControls> = ({
  videoSrc,
  imgSrc,
  title,
  text, link, controls, playerProps, isCurrent
}): JSX.Element => {
  return (
    <PageTopCustom id="hero">
      <PageTop
        height="medium"
        backgroundColor="black"
      >
        <HeroVideo
          contrastGradient
          src={videoSrc}
          fallbackImage={imgSrc}
          playerProps={{
            ...playerProps,
            url: isCurrent ? videoSrc : undefined,
            pip: false,
            stopOnUnmount: true
          }}
          className={isCurrent ? "homepage visible" : ""}
        />

        <ColumnWidthWrapper className="top-content">
          <h3 className="heading_small">
            {title}
          </h3>

          <p className="heading_large">
            {text}
          </p>

          <div className="section-cta-container">
            <p
              className="insights-link"
              style={{ position: "relative" }}
            >
              <span className="off-screen">
                Click here to view our
                {" "}
              </span>

              {link && (
                <Link
                  type="styled-box"
                  color="white"
                  href={link}
                  uppercase
                >
                  Case study
                </Link>
              )}

            </p>
          </div>
        </ColumnWidthWrapper>

        <ColumnWidthWrapper className="controls-wrapper">
          {controls}
        </ColumnWidthWrapper>
      </PageTop>
    </PageTopCustom>
  );
};

export default PageHeaderSlide;

const PageTopCustom = styled.div`
	color: white;

  .top-content-container {
    .top-content-wrapper {
      position: absolute;
      left: 0;
      top: 0;

      .top-content {
        z-index: 4;

				.heading_small {
					text-transform: uppercase;
					font-weight: normal;
					letter-spacing: 0.1em;
					font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "small_3"
  })};
				}
				
        .heading_large {
          transition: 0.2s ease-in-out;
					padding-top: .5em;
          width: 100%;
          max-width: 90%;
          display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;  
					overflow: hidden;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }
      }
    }
  }

	.section-cta-container {
    width: 100%;
		padding-top: 3rem;
    p {
      padding-top: 0;
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "bold"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};

      a {
        color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
        border-bottom-color: rgba(255, 255, 255, 0.4);

        &:hover {
          border-bottom-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .controls-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    pointer-events: none;
    & > * {
      pointer-events: all;
    }
  }

  @media (min-width: 375px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading_large {
          }
        }
      }
    }
  }

  @media (min-width: 480px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading_large {
						font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
					}
        }
        }
      }
			.section-cta-container {
      padding-top: 3rem;
    }
    }
  }

  @media (min-width: 568px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
					.heading_large {
						font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "subhead_3"
  })};
					}
        }
      }
    }
  }

  @media (min-width: 768px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
					.heading_large {
						-webkit-line-clamp: 3;
						max-width: 30ch;
					}
        }
      }
    }
    .controls-wrapper {
      bottom: 40px;
    }
  }

  @media (min-width: 900px) {
		.top-content-container {
      .top-content-wrapper {
        .top-content {
					.heading_large {
						max-width: 40ch;
					}
        }
      }
    }
	}

  @media (min-width: 960px) {
		.section-cta-container {
      p {
				padding-top: 2rem;
      }
    }
  }

  @media (min-width: 1280px) {
    .top-content-container {
      min-height: min(640px, calc(100vh - var(--nav-height)));

      .top-content-wrapper {
        .top-content {
        }
      }
    }
    .controls-wrapper {
      bottom: var(--nav-height);
    }
  }

  @media (min-width: 1440px) {
    .top-content-container {      
      min-height: min(768px, calc(100vh - var(--nav-height)));
      .top-content-wrapper {
        .top-content {
          .heading_large {
						font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "display_1"
  })};
            max-width: 30ch;
					}
					}
        }
      }

      .controls-wrapper {
        right: calc(50% + 20px);
        transform: translateX(50%);
      }
    }
  }

  @media (min-width: 1920px) {
    .top-content-container {
      min-height: min(800px, calc(100vh - var(--nav-height)));
      .top-content-wrapper {
        .top-content {
          
        }
      }
    }
  }
`;
