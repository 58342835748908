import React from "react";
import styled from "styled-components";
import Slider from "react-slick";

import { slides } from "src/pages";

const SliderControls = ({ getSlider, current }: {getSlider: (() => Slider | undefined), current: number}): JSX.Element => {
  // if (slides.length < 2 || getSlider() === undefined) {
  //   return null;
  // }

  const dot = (isCurrent: boolean) => (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      stroke="currentColor"
      strokeWidth="1.5"
      fill={isCurrent ? "currentColor" : "none"}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <circle
        cx="12"
        cy="12"
        r={isCurrent ? "6" : "5"}
        style={{ transition: "all 200ms ease-in-out" }}
      />
    </svg>
  );

  return (
    <div
      style={{
        position: "absolute",
        right: 0,
        bottom: 0
      }}
    >
      {slides.map((_, i) => (
        <Styled.Button
          onClick={() => getSlider()?.slickGoTo(i)}
          key={i}
          aria-current={i === current}
          style={{
            appearance: "none",
            border: "none",
            background: "none",
            outline: "none",
            color: "white"
          }}
        >
          {dot(i === current)}
        </Styled.Button>
      ))}
    </div>
  );
};

const Styled = {
  Button: styled.button`
    & svg circle {
      r: 5
    }
    &[aria-current="true"] svg circle {
      r: 6
    }
  
    @media screen and (min-width: 1440px) {
      & svg circle {
        r: 6
      }
      &[aria-current="true"] svg circle {
        r: 7
      }
    }
  `
};

export default SliderControls;